<template>
  <div>
    <div
      class="box-border min-h-screen px-2 pt-8 pb-5 home-body-bg md:pt-10 md:px-0"
      style="font-family: 'Lato', sans-serif"
    >
      <slot />

      <footer class="flex justify-center max-w-lg mx-auto text-white">
        <span class="font-bold">{{ title }} - Build Change &copy {{ year }}</span>
      </footer>

    </div>
  </div>
</template>

<script setup lang="ts">
const year = new Date().getFullYear();
const title = useRuntimeConfig().public.title;
</script>
